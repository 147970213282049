import {Collapse, Drawer, Icon, Link, List, ListItemButton, ListItemText} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import {DRAWER_WIDTH} from '../config';
import Container from '@mui/material/Container/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paths from "../paths";
import {useEffect, useState} from "react";
import {
    AccountBalance,
    AttachMoney,
    ExpandLess,
    ExpandMore,
    Grass, HomeWork,
    Map,
    Person,
    Store,
    SvgIconComponent,
    Thermostat,
    Work
} from "@mui/icons-material";
import {NavLink, useLocation} from 'react-router-dom';


const fullSurveyNavItems: { [key: string]: { url: string, title: string, icon: SvgIconComponent } } = {
    soilTests: {
        url: Paths.FULL_SURVEY.SOIL_HEALTH,
        title: "Soil Health",
        icon: Grass
    },
    infrastructure: {
        url: Paths.FULL_SURVEY.INFRASTRUCTURE,
        title: "Infrastructure",
        icon: HomeWork
    },
    weatherClimate: {
        url: Paths.FULL_SURVEY.WEATHER_CLIMATE,
        title: "Weather & Climate",
        icon: Thermostat
    },
    markets: {
        url: Paths.FULL_SURVEY.MARKETS,
        title: "Markets",
        icon: Store
    },
    operators: {
        url: Paths.FULL_SURVEY.OPERATORS,
        title: "Operators",
        icon: Person
    },
    finances: {
        url: Paths.FULL_SURVEY.FINANCES,
        title: "Finances",
        icon: AttachMoney
    },
    funding: {
        url: Paths.FULL_SURVEY.FUNDING,
        title: "Funding",
        icon: AccountBalance
    },
    enterprises: {
        url: Paths.FULL_SURVEY.ENTERPRISES,
        title: "Enterprises",
        icon: Work
    },
    // partners: {
    //     url: Paths.FULL_SURVEY.PARTNERS,
    //     title: "Partners",
    //     icon: People
    // },
}

const planningNavItems: { [key: string]: { url: string, title: string, icon: SvgIconComponent } } = {
    overview: {
        url: Paths.PLANNING.OVERVIEW,
        title: "Strategy",
        icon: Map
    },
    finances: {
        url: Paths.PLANNING.FINANCES,
        title: "Finances",
        icon: AttachMoney
    },
    operations: {
        url: Paths.PLANNING.OPERATIONS,
        title: "Co-pilot",
        icon: Grass
    },
    // today: {
    //     url: Paths.PLANNING.TODAY  ,
    //     title: "Today",
    //     icon: TodayIcon
    // },
}

const INDENT_DISTANCE = 28;

export default function NavDrawer() {
    const [fullSurveyOpen, setFullSurveyOpen] = useState(false);
    const [planningOpen, setPlanningOpen] = useState(false);
    const location = useLocation(); // This hook returns the current location object
    const handleFullSurveyClick = () => {
        setFullSurveyOpen(!fullSurveyOpen);
    };

    const handlePlanningClick = () => {
        setPlanningOpen(!planningOpen);
    };

    let fullSurveySubPathActive = false;
    Object.values(fullSurveyNavItems).map((item) => {
        const selectedOption = location.pathname === item.url
        if (selectedOption) {
            fullSurveySubPathActive = true;
        }
        return null
    });

    let planningSubPathActive = false;
    Object.values(planningNavItems).map((item) => {
        const selectedOption = location.pathname === item.url
        if (selectedOption) {
            planningSubPathActive = true;
        }
        return null
    });

    useEffect(() => {
        setFullSurveyOpen(fullSurveySubPathActive);
        setPlanningOpen(planningSubPathActive);
    }, [fullSurveySubPathActive]);

    return (
        <Box component="nav">
            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    display: {xs: 'none', sm: 'block'},
                    boxSizing: 'border-box',
                    width: DRAWER_WIDTH
                }}
            >
                <Container
                    sx={{
                        width: DRAWER_WIDTH,
                        paddingX: {xs: '0px', sm: '0px'},
                    }}
                >
                    <Toolbar disableGutters={true} sx={{paddingLeft: '24px'}}>
                        <Typography variant="h4" component="div" sx={{flexGrow: 1}}>
                            Rooted Ag
                        </Typography>
                    </Toolbar>


                    <List sx={{paddingTop: 0}}>
                        {/* Context Report is a single link*/}
                        <Link key={"Overview"} component={NavLink} to={Paths.CONTEXT_REPORT} underline="none"
                              color="inherit">
                            <ListItemButton sx={{pl: `${INDENT_DISTANCE}px`}}
                                            selected={location.pathname === Paths.CONTEXT_REPORT}>
                                <ListItemText primary={"Overview"}/>
                            </ListItemButton>
                        </Link>
                        {/*Full Survey Report contains a sublist*/}
                        <ListItemButton key={"Full Survey Report"} sx={{pl: `${INDENT_DISTANCE}px`}}
                                        onClick={handleFullSurveyClick}>
                            <ListItemText primary={"Data"}/>
                            {fullSurveyOpen ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={fullSurveyOpen} timeout="auto" unmountOnExit>
                            <List sx={{padding: '0px'}}>
                                {
                                    Object.values(fullSurveyNavItems).map((item) => {
                                        return <Link key={item.title} component={NavLink} to={item.url}
                                                     sx={{color: "black"}} underline={'none'}>
                                            <ListItemButton sx={{pl: `${INDENT_DISTANCE}px`}}
                                                            selected={location.pathname === item.url}>
                                                <Icon style={{fontSize: 20, stroke: '1.5', marginRight: '10px'}}>
                                                    <item.icon/>
                                                </Icon>
                                                <ListItemText primary={item.title}/>
                                            </ListItemButton>
                                        </Link>
                                    })
                                }
                            </List>
                        </Collapse>
                        {/*Planning contains a sublist*/}
                        <ListItemButton key={"Operations"} sx={{pl: `${INDENT_DISTANCE}px`}}
                                        onClick={handlePlanningClick}>
                            <ListItemText primary={"Planning"}/>
                            {planningOpen ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={planningOpen} timeout="auto" unmountOnExit>
                            <List sx={{padding: '0px'}}>
                                {
                                    Object.values(planningNavItems).map((item) => {
                                        return <Link key={item.title} component={NavLink} to={item.url}
                                                     sx={{color: "black"}} underline={'none'}>
                                            <ListItemButton sx={{pl: `${INDENT_DISTANCE}px`}}
                                                            selected={location.pathname === item.url}>
                                                <Icon style={{fontSize: 20, stroke: '1.5', marginRight: '10px'}}>
                                                    <item.icon/>
                                                </Icon>
                                                <ListItemText primary={item.title}/>
                                            </ListItemButton>
                                        </Link>
                                    })
                                }
                            </List>
                        </Collapse>
                    </List>
                </Container>
            </Drawer>
        </Box>
    );
}
